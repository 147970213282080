import { MenuTriggerProps, MenuTrigger } from 'react-aria-components';
import { MenuItem, MenuProps, Menu } from '../../atoms/menu/menu';
import { Popover } from '../../atoms/popover/popover';
import { IconButton } from '../icon-button/icon-button';

export function ActionMenu<T extends object>({
  children,
  isOpen,
  defaultOpen,
  onOpenChange,
  trigger,
  size,
  surface,
  intent,
  ...props
}: MenuProps<T> &
  MenuTriggerProps & {
    surface?: 'light' | 'dark';
    intent?: 'primary' | 'secondary' | 'tertiary';
  }) {
  return (
    <MenuTrigger
      defaultOpen={defaultOpen}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      trigger={trigger}>
      <IconButton
        aria-label="action menu"
        icon="ellipsis"
        intent={intent}
        size={size === 'small' ? 'sm' : 'lg'}
        variant={surface}
      />
      <Popover className="p-1.5" offset={0} placement="bottom end">
        <Menu size={size} {...props}>
          {children}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}

ActionMenu.Item = MenuItem;
